import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
function UncontrolledExample() {
  return (
    <Carousel slide={false}>
      <Carousel.Item>

        <Carousel.Caption>

          <Card>
            <Card.Body>
              <article>


                <Card.Text> <span className="badge bg-primary"><time>fev-juillet 2024 </time></span></Card.Text>
                <Card.Title><h3><a href="https://insy2s.com/" target="_blank">EDEN 62</a></h3></Card.Title>
                <Card.Text><small>Syndicat mixte</small></Card.Text>


                <Card.Text>Agent d'aménagement paysager</Card.Text>

                <Card.Text>CDD</Card.Text>

                 



              </article>
            </Card.Body>
          </Card>







        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>

        <Carousel.Caption>



          <Card>
            <Card.Body>
              <article>


                <Card.Text> <span className="badge bg-primary"><time>sept-nov 2021 </time></span> </Card.Text>
                <Card.Title><h3><a href="https://insy2s.com/" target="_blank">INSY2S</a></h3></Card.Title>
                <Card.Text>
                  <small>Entreprise du Secteur du Numérique (ESN) </small></Card.Text>

                  <Card.Text> Conception / Développement d'applications </Card.Text>

                  <Card.Text>Stage </Card.Text>
               



              </article>
            </Card.Body>
          </Card>




        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>

        <Carousel.Caption>


          <Card>
            <Card.Body>

              <article>


                <Card.Text> <span className="badge bg-primary"><time>2019</time> - <time>2017</time></span> </Card.Text>
                <Card.Title>
                  <h3><a href="https://camille-binet.com" target="_blank">Camille Binet Webdesign</a></h3></Card.Title>
                <Card.Text>

                  <small>Micro-entreprise</small> </Card.Text>

                <Card.Text>Création / développement d'applications </Card.Text>

                <Card.Text>Micro-entrepreneur </Card.Text>

                



              </article>

            </Card.Body></Card>



        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item>

        <Carousel.Caption>


          <Card>
            <Card.Body>
              <article>



                <Card.Text> <span className="badge bg-primary"><time>2017</time> - <time>2012</time></span> </Card.Text>
                <Card.Title><h3><a href="https://www.axecibles.com/" target="_blank">AXECIBLES</a></h3></Card.Title>


                <Card.Text><small>Agence web</small> </Card.Text>

                <Card.Text>Webdesigner / Intégrateur </Card.Text>

                <Card.Text>CDI </Card.Text>

               



              </article>
            </Card.Body>
          </Card>




        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;